<template>
  <div class="mt-5">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="">
      <div class="bg p-3">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des motifs de ne pas travailler</h4>
          </div>
          <form class="d-flex my-1">
            <div class="input-group mb-3 me-4">
              <input type="text" class="form-control" placeholder="" aria-label="Example text with button addon" aria-describedby="button-query">
              <button type="submit" class="style_btn btn btn-primary"  id="button-query"><i class="bi bi-search"></i></button>
            </div>
              <ne-travail-pas-edit ref="NeTravailPasditor" @saved="updateOrCreateNeTravailPas" btnText="Créer un ne-travail-pas"  :item="activeNeTravailPas" title="Ajouter d'une nouvelle option"></ne-travail-pas-edit>
          </form>
        </div>
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table show-empty empty-text="Aucune donnée à afficher" :fields="tableFields" class="align-middle" striped :items="tableData">
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a  @click.prevent="editNeTravailPas(data.item)" class="btn btn-success rounded-0 btn-sm me-1"><i class="bi bi-pencil-square"></i></a>
                  <a class="btn btn-info rounded-0 btn-sm me-1"><i class="bi bi-info-square"></i></a>
                  <a @click.prevent="deleteOneNeTravailPas(data.item)" class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>Affichage de l’élément 1 à 38 de 38 éléments</p>
           <!-- <b-pagination
              :value="mxPagination.page"
              :total-rows="mxPageCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import NeTravailPasEdit from '@/components/espace/admin/ne-travail-pas/NeTravailPasEdit'

export default {
  components: {
    NeTravailPasEdit
  },
  data () {
    return {
      activeNeTravailpas: {},
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Paramètres',
          href: '#'
        },
        {
          text: 'NeTravailPass',
          active: true
        }
      ]
    }
  },
  created () {
    this.fetchNeTravailPass()
  },
  computed: {
    ...mapGetters({
      neTravailPass: 'netravailpas/neTravailPass'
    }),
    tableFields () {
      return [
        'index',
        { key: 'name', label: 'Libellé' },
        // { key: 'structure', label: 'Structure' },
        'actions',
      ]
    },
    tableData () {
      return this.neTravailPass.map(item => ({ ...item }))
    }
  },
  methods: {
    ...mapActions({
      fetchNeTravailPass: 'netravailpas/fetchNeTravailPass',
      createOrUpdateNeTravailPas: 'netravailpas/createOrUpdateNeTravailPas',
      deleteNeTravailPas: 'netravailpas/deleteNeTravailPas',
    }),
    editNeTravailPas (netravailpas) {
      console.log(netravailpas)
      this.activeNeTravailPas = { ...netravailpas }
      this.$refs['NeTravailPasditor'].show()
    },
    updateOrCreateNeTravailPas (netravailpas) {
      console.log(netravailpas)
      this.createOrUpdateNeTravailPas(netravailpas).then(data => {
        console.log(data)
        this.$toast.success("Opération réussie!", {
            position: 'bottom-right',
            duration: 5000
          })
      })
    },
    deleteOneNeTravailPas(netravailpas) {
      console.log(netravailpas);
      this.$confirm({
        message: `Confirmez - vous la suppression?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
             console.log(netravailpas);
            this.deleteNeTravailPas(netravailpas).then((data) => {
              console.log(data);
              this.$toast.success("Opération réussie!", {
                position: "bottom-right",
                duration: 5000,
              });
            });
          }
        },
      });
    },
  }
}
</script>

<style>

</style>